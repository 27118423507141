import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'
import { graphql, Link } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'

import { useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
	data: any
}

const Register = ({ data }: Props) => {
	const [notificationCheckbox, setNotificationCheckbox] = useState(true)

	const [termsCheckbox, setTermsCheckbox] = useState(false)
	const [termsCheckboxError, setTermsCheckboxError] = useState(false)

	const [firstName, setFirstName] = useState('')
	const [firstNameError, setFirstNameError] = useState(false)
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('')

	const [lastName, setLastName] = useState('')
	const [lastNameError, setLastNameError] = useState(false)
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState('')

	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [emailErrorMessage, setEmailErrorMessage] = useState('')

	const [optionDescribe, setOptionDescribe] = useState('')
	const [optionDescribeError, setOptionDescribeError] = useState(false)
	const [optionDescribeErrorMessage, setOptionDescribeErrorMessage] =
		useState('')

	const [recaptchaValue, setRecaptchaValue] = useState('')
	const [recaptchaError, setRecaptchaError] = useState(false)
	const recaptchaRef = useRef<ReCAPTCHA>(null)

	const [formSuccess, setFormSuccess] = useState(false)
	const [formErrorMessage, setFormErrorMessage] = useState('')

	const [formLoading, setFormLoading] = useState(false)

	const onFirstNameInputChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { value } = e.target as HTMLInputElement
		setFirstNameError(false)
		setFirstNameErrorMessage('')
		setFirstName(value)
	}

	const checkNameInputOK = (value: string): boolean => {
		if (value === '') {
			setFirstNameError(true)
			setFirstNameErrorMessage('Please enter first name')
			return false
		}
		setFirstNameError(false)
		setFirstNameErrorMessage('')
		return true
	}

	const onLastNameInputChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { value } = e.target as HTMLInputElement
		setLastNameError(false)
		setLastNameErrorMessage('')
		setLastName(value)
	}

	const checkLastNameInputOK = (value: string): boolean => {
		if (value === '') {
			setLastNameError(true)
			setLastNameErrorMessage('Please check your last name')
			return false
		}
		setLastNameError(false)
		setLastNameErrorMessage('')
		return true
	}

	const onEmailInputChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { value } = e.target as HTMLInputElement
		setEmailError(false)
		setEmailErrorMessage('')
		setEmail(value)
	}

	const checkEmailInputOK = (value: string): boolean => {
		const emailRegex =
			// eslint-disable-next-line no-control-regex
			/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
		const validationsPassed = emailRegex.test(value)

		if (!validationsPassed) {
			setEmailError(true)
			setEmailErrorMessage('Please check your email')
			return false
		}
		setEmailError(false)
		setEmailErrorMessage('')
		return true
	}

	const onOptionDescribeInputChange = (
		e: React.FormEvent<HTMLSelectElement>,
	) => {
		const { value } = e.target as HTMLSelectElement
		setOptionDescribeError(false)
		setOptionDescribeErrorMessage('')
		setOptionDescribe(value)
	}

	const checkOptionDescribeInputOK = (value: string): boolean => {
		if (value === '') {
			setOptionDescribeError(true)
			setOptionDescribeErrorMessage('Please select an option')
			return false
		}
		setOptionDescribeError(false)
		setOptionDescribeErrorMessage('')
		return true
	}

	const onTermsCheckboxInputChange = () => {
		setTermsCheckboxError(false)
		setTermsCheckbox(!termsCheckbox)
	}

	const onNotificationCheckboxInputChange = () => {
		setNotificationCheckbox(!notificationCheckbox)
	}

	const handleSubmit = async (e: React.SyntheticEvent) => {
		setFormLoading(true)
		e.preventDefault()
		setFormErrorMessage('')

		const isNameInputOK = checkNameInputOK(firstName)
		const isLastNameInputOK = checkLastNameInputOK(lastName)
		const isEmailInputOK = checkEmailInputOK(email)
		const isOptionDescribeInputOK = checkOptionDescribeInputOK(optionDescribe)
		const isRecaptchaInputOK = recaptchaValue !== ''
		setRecaptchaError(!isRecaptchaInputOK)
		setTermsCheckboxError(!termsCheckbox)
		if (
			isEmailInputOK &&
			isNameInputOK &&
			isLastNameInputOK &&
			isOptionDescribeInputOK &&
			recaptchaValue !== '' &&
			termsCheckbox &&
			!recaptchaError
		) {
			const result = await fetch(`${'/submit-takeda.php'}`, {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
				},
				body: JSON.stringify({
					FirstName: firstName,
					LastName: lastName,
					EmailAddress: email,
					OptionDescribe: optionDescribe,
					IsContactable: notificationCheckbox.toString(),
					reCaptcha: recaptchaValue,
				}),
			})

			const json = await result.json()
			if (json.Status === 'OK') {
				setFormSuccess(true)
				window.scrollTo(0, 0)
			} else {
				window.grecaptcha.reset()
				if (json.Status === 'Failed') {
					setFormErrorMessage(json.StatusMessage)
				} else {
					setFormErrorMessage('An error occurred. Please try again later.')
				}
			}
		}
		setFormLoading(false)
	}

	return (
		<Layout>
			<Hero bgImage={data.heroImage} imageAlt=''>
				<h1 className='text-white'>Sign Up Now</h1>
			</Hero>
			<div className='grid grid-cols-12 py-10'>
				{!formSuccess ? (
					<div className='md:col-start-3 col-start-2 md:col-span-8 col-span-10'>
						<h2 className='text-pompadourViolet text-2xl font-bold'>
							Register below to receive useful information and updates
						</h2>
						<div className='text-darkGrey text-sm mt-2'>*Required Fields</div>
						<form className='mt-10' onSubmit={handleSubmit}>
							<div className='md:grid md:grid-cols-2 mb-10'>
								<div className='md:pr-5 md:mb-0 mb-10'>
									<label
										className='mb-2 font-bold text-pompadourViolet'
										htmlFor='firstName'
									>
										First Name*
									</label>
									<input
										type='text'
										id='firstName'
										value={firstName}
										name='First name'
										className={`${
											firstNameError ? 'border-2 border-red' : 'border-2 border-lightGrey'
										} bg-lightGrey p-2 w-full focus:outline-0`}
										onChange={onFirstNameInputChange}
										onBlur={(e) => checkNameInputOK(e.target.value)}
									/>
									<div className='text-sm text-red'>{firstNameErrorMessage}</div>
								</div>
								<div className='md:pl-5'>
									<label
										className='mb-2 font-bold text-pompadourViolet'
										htmlFor='lastName'
									>
										Last Name*
									</label>
									<input
										type='text'
										id='lastName'
										name='Last Name'
										value={lastName}
										className={`${
											lastNameError ? 'border-2 border-red' : 'border-2 border-lightGrey'
										} bg-lightGrey p-2 w-full focus:outline-0`}
										onChange={onLastNameInputChange}
										onBlur={(e) => checkLastNameInputOK(e.target.value)}
									/>
									<div className='text-sm text-red'>{lastNameErrorMessage}</div>
								</div>
							</div>
							<div className='mb-10'>
								<label className='mb-2 font-bold text-pompadourViolet' htmlFor='email'>
									Email address*
								</label>
								<input
									type='email'
									id='email'
									value={email}
									name='Email'
									className={`${
										emailError ? 'border-2 border-red' : 'border-2 border-lightGrey'
									} bg-lightGrey p-2 w-full focus:outline-0`}
									onChange={onEmailInputChange}
									onBlur={(e) => checkEmailInputOK(e.target.value)}
								/>
								<div className='text-sm text-red'>{emailErrorMessage}</div>
							</div>
							<div className='mb-10'>
								<label className='mb-2 font-bold text-pompadourViolet' htmlFor='select'>
									Select the option that best describes you*
								</label>
								<select
									id='select'
									value={optionDescribe}
									name='Select'
									className={`${
										optionDescribeError
											? 'border-2 border-red'
											: 'border-2 border-lightGrey'
									} bg-lightGrey p-2 w-full focus:outline-0`}
									onChange={onOptionDescribeInputChange}
									onBlur={(e) => checkOptionDescribeInputOK(e.target.value)}
								>
									<option value=''>Select</option>
									<option value='patient'>I&rsquo;m a Patient</option>
									<option value='caregiver'>I&rsquo;m a Care Partner</option>
								</select>
								<div className='text-sm text-red'>{optionDescribeErrorMessage}</div>
							</div>
							<div className='flex mb-5'>
								<div>
									<div
										className='h-5 w-5 bg-lightGrey mr-3 mt-1'
										onClick={onNotificationCheckboxInputChange}
									>
										{notificationCheckbox && (
											<StaticImage src='../images/checkbox-check.png' alt='' />
										)}
										<input
											id='notification'
											type='checkbox'
											className='hidden'
											onChange={onNotificationCheckboxInputChange}
										/>
									</div>
								</div>
								<label htmlFor='notification'>
									Yes! I would like to receive emails about product and disease-state
									information from Takeda, its affiliates, service providers and
									co-promotion partners.
								</label>
							</div>
							<div className='flex mb-5'>
								<div>
									<div
										className={`h-5 w-5 bg-lightGrey mr-3 mt-1 ${
											termsCheckboxError
												? 'border-2 border-red'
												: 'border-2 border-lightGrey'
										}`}
										onClick={onTermsCheckboxInputChange}
									>
										{termsCheckbox && (
											<StaticImage src='../images/checkbox-check.png' alt='' />
										)}
										<input
											id='terms'
											type='checkbox'
											className='hidden'
											onChange={onTermsCheckboxInputChange}
										/>
									</div>
								</div>
								<label htmlFor='terms'>
									I have read and understand the{' '}
									<a
										href='https://www.takeda.com/en-us/terms-of-use'
										target='_blank'
										className='font-bold text-mediumVioletRed'
										rel='noreferrer'
									>
										Terms of Use
									</a>{' '}
									and{' '}
									<a
										href='https://www.takeda.com/privacy-notice'
										className='font-bold text-mediumVioletRed'
										target='_blank'
										rel='noreferrer'
									>
										Privacy Policy
									</a>{' '}
									of Takeda.*
								</label>
							</div>
							<div className='mb-8'>
								By clicking Submit, you agree to receive product and disease-state
								information from Takeda, its affiliates, service providers and
								co-promotion partners.
							</div>
							{process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}
							<div className='mb-8'>
								<ReCAPTCHA
									sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
									onChange={(value: any) => {
										setRecaptchaValue(value)
										setRecaptchaError(false)
									}}
									onExpired={() => {
										recaptchaRef.current.reset()
									}}
								/>
								{recaptchaError && (
									<div className='text-sm text-red'>Please solve reCAPTCHA</div>
								)}
							</div>
							<div>
								<button
									className="flex-grow bg-mediumVioletRed bg-cover bg-[url('../images/cta-bg.png')]
							bg-[length:20%_60%] bg-left-bottom bg-no-repeat flex justify-between
							py-3 px-1 text-white rounded-md"
									type='submit'
									disabled={formLoading}
								>
									<div className='px-8'>{formLoading ? 'Please wait..' : 'Submit'}</div>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='h-6 w-6'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
										strokeWidth='2'
									>
										<path strokeLinecap='round' strokeLinejoin='round' d='M9 5l7 7-7 7' />
									</svg>
								</button>
								{formErrorMessage && (
									<div className='text-sm text-red mt-3'>{formErrorMessage}</div>
								)}
							</div>
						</form>
					</div>
				) : (
					<div className='md:col-start-3 col-start-2 md:col-span-8 col-span-10'>
						<h2 className='text-pompadourViolet text-2xl font-bold'>
							Thank you! You have successfully registered.
						</h2>
						<div className='mt-3'>You will hear from us with any updates.</div>
						<div className='mt-3'>
							Back to{' '}
							<Link to={'/'} className='text-mediumVioletRed underline font-bold'>
								Home
							</Link>
						</div>
					</div>
				)}
			</div>
		</Layout>
	)
}
export default Register

export const query = graphql`
	{
		heroImage: file(relativePath: { eq: "register/hero-register.png" }) {
			...heroImage
		}
	}
`
