import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

type Props = {
	children?: React.ReactNode
	bgImage: any
	imageAlt?: string
}

const Hero = ({ children, bgImage, imageAlt = '' }: Props) => {
	return (
		<div className='flex py-0 justify-center items-center'>
			<GatsbyImage
				image={bgImage.childImageSharp.gatsbyImageData}
				alt={imageAlt}
				className='hidden sm:block w-full'
				backgroundColor='#c31b7d'
			/>
			<StaticImage
				src='../images/hero-mobile-bg.png'
				alt=''
				className='w-full sm:hidden'
				formats={['auto', 'webp']}
				quality={100}
				aspectRatio={3}
				layout='fullWidth'
				backgroundColor='#c31b7d'
				placeholder='none'
			/>
			<div className='absolute w-10/12 md:text-5xl text-3xl'>{children}</div>
		</div>
	)
}

export default Hero
